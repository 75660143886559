<template>
  <div class="w-full h-screen bg-mala wrap-info-page bg-mala-white relative">
    <app-drawer
      :direction="'left'"
      :exist="true"
      ref="drawer"
    >
      <app-content-drawer />
    </app-drawer>
    <app-navigation-menu :openMenu="openMenu" />
    <div class="absolute w-full top-0 left-0 bg-transparent">
      <div class="flex flex-col items-center text-center font-JetBrains
        mt-80 xs:mt-96
        mx-auto
        w-72 xs:w-96 sm:w-info
      ">
        <div class="tracking-tight font-black text-malahierba-blue text-xs leading-2">
          <p>BIENVENIDX!!</p>
          <p>MALAHIERBA TROPICAL TE QUIERE</p>
          <p class="tracking-tighter">** cartografía sonora * grafitería musical * repetidor de onda corta * blog ocioso sin rigor investigativo **</p>
          <p>malahierba.radio@gmail.com</p>
        </div>
        <div class="pb-14 text-center text-xss text-malahierba-blue mt-6">
          <p class="font-base xs:px-4 sm:px-0">
          Malahierba Tropical es una cartografía dedicada a seleccionar, compilar y ubicar en el mapa distintas expresiones musicales de un territorio, ciudad, barrio o esquina. Queremos escuchar y reconstruir un paisaje musical de cada lugar, a partir de playlist, videos, sesiones en vivo o programas de radio.
          </p>
          <p class="font-base xs:px-4 sm:px-0">
            Todos los contenidos son tomados de otros medios y archivos de internet. Este proyecto renuncia a lucrarse o expandirse a través de relaciones económicas y se construye a través de la colaboración, el afecto y la libre circulación de la música. Si usted desea sumar a esta cartografía un compilado de su barrio o ciudad, escriba a malahierba.radio@gmail.com. Si alguno de los contenidos de este mapa es de su propiedad o autoría, y desea que sea eliminado, siéntase libre y segurx de escribirnos.
          </p>
          <p class="font-base xs:px-4 sm:px-0">Esto se cocina a fuego lento, no lo pierda de vista. 2024</p>
        </div>
        <!-- Copyright text -->
        <div v-if="false" class="text-justify text-xss text-malahierba-blue mt-6">
          <p class="font-base xs:px-4 sm:px-0">Frecuentemente se hace referencia a la copia ilegal como piratería, expresando de manera implícita, que hacer copias ilegales es equiparable a atacar barcos en alta mar, secuestrar y asesinar gente. Nosotros preferimos utilizar una expresión positiva como compartir música con el vecino. Las copias privadas, las descargas en internet y la circulación de la música, contribuyen a la difusión de las obras y no tienen como finalidad el lucro. La piratería gestionada por el crimen organizado, en cambio, es la contraparte extralegal de los excesos en la explotación de los derechos de autor por parte de la industria que hace que el acceso a las obras protegidas sea artificialmente oneroso, prohibitivo y tal vez en ocasiones equiparable al atraco y secuestro a los autores.</p>
          <p class="font-bold mt-2 xs:px-4 sm:px-0">Este proyecto renuncia a lucrarse o expandirse a través de relaciones económicas y elige los medios colaborativos y afectivos. Si usted es dueñx de alguno de los contenidos de esta cartografía y quiere que sea eliminado, siéntase libre y segurx de escribirnos. </p>
        </div>
        <div v-if="false" class="mt-8 xs:px-4 sm:px-0">
          <h6 class="tracking-tighter sm:tracking-widest font-semibold text-malahierba-blue text-xs xs:text-sm leading-1 xs:leading-3  bg-malahierba-green mb-2">
            __ ::: c o l a b o r a c i o n e s __ :::
          </h6>
          <a
            target="blank"
            href="https://zudaka.hotglue.me/"
            class="block hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs mb-1"
          >
            * el Zudaca boy *
          </a>
          <a
            target="blank"
            href="https://www.mixcloud.com/juliendonk/"
            class="block hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs mb-1"
          >
            * Basuritas Records *
          </a>
          <a
            target="blank"
            href="https://www.mixcloud.com/Radioguau/"
            class="block hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs mb-1"
          >
            * Radio Guau *
          </a>
          <a
            target="blank"
            href="https://archive.org/details/@elsuiche"
            class="block hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs mb-1"
          >
            * El Suiche *
          </a>
          <a
            target="blank"
            href="https://callejonesciudadpodrida.podbean.com/"
            class="block hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs mb-1"
          >
            * Callejones de la Ciudad Podrida *
          </a>
          <a
            target="blank"
            href="https://www.instagram.com/estoesunlibro"
            class="hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs"
          >
            * E Tonatiuh Trejo *
          </a>
          <br/>
          <a
            target="blank"
            href="https://www.youtube.com/@festivalporlasolidaridadye2185"
            class="hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs"
          >
            * La Distri *
          </a>

        </div>
        <div v-if="false" class="my-4 flex flex-col xs:px-4 sm:px-0">
          <h6 class="tracking-tighter sm:tracking-widest font-semibold text-malahierba-blue text-xs xs:text-sm leading-1 xs:leading-3 bg-malahierba-green mb-2">
             __ ::: r e c o m e n d a c i o n e s __ :::
          </h6>
          <a
            target="blank"
            href="http://radio.garden/"
            class="hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs"
          >
            * Radio Garden *
          </a>
          <a
            target="blank"
            href="https://proyectosonidero.com/"
            class="hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs"
          >
            * Proyecto Sonidero *
          </a>
          <a
            target="blank"
            href="https://everynoise.com/"
            class="hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs"
          >
            * Every noise *
          </a>
          <a
            target="blank"
            href="https://www.audiomapa.org/"
            class="hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs"
          >
            * Cartografía paisaje sonoro latinoamérica *
          </a>
          <a
            target="blank"
            href="https://musicmap.info/"
            class="hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs"
          >
            * Music map *
          </a>
          <!-- <a
            target="blank"
            href="https://www.sonideros.net/"
            class="hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs"
          >
            * Sonideros *
          </a> -->
          <a
            target="blank"
            href="https://chachafruto.blogspot.com/"
            class="hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs"
          >
            * el Sonido del Chachafruto *
          </a>
          <a
            target="blank"
            href="http://www.mondongorecords.com/"
            class="hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs"
          >
            * Mondongo Records *
          </a>
          <a
            target="blank"
            href="https://lengua.la/"
            class="hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs"
          >
            * La lengua *
          </a>
          <!-- <a
            target="blank"
            href="http://volcanmudo.com/radio/"
            class="hover:text-malahierba-green hover:bg-malahierba-blue tracking-tight font-semibold text-malahierba-blue text-xss xs:text-xs"
          >
            * La Mala Estrella *
          </a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from '../components/Drawer.vue'
import NavigationMenu from '../components/NavigationMenu.vue'
import ContentDrawer from '../components/ContentDrawer.vue'
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import drawerHideClose from '../composables/drawerHideClose'
export default {
  name: 'info-view',
  components: {
    'app-navigation-menu': NavigationMenu,
    'app-drawer': Drawer,
    'app-content-drawer': ContentDrawer
  },
  setup () {
    const store = useStore()
    store.dispatch('loadInformation')
    const { drawer, openMenu } = drawerHideClose()
    onMounted(() => {
      store.dispatch('setScreen', window.innerWidth)
      store.dispatch('setDrawer', drawer)
    })
    return {
      drawer,
      openMenu
    }
  }
}
</script>
